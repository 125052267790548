import Auth from "./auth";

import routes from "../data/routes.json";
import Language from "./language";

export default class Remote {
	constructor() {
		this.routes = routes;
		// console.log(routes);
	}

	composeUrl(name, params = [], query = {}) {
		try {
			const route = this._findRoute(name);
			return {url: `${this._addParams(route.uri, params, query)}`, route};
		} catch (e) {
			console.error(e);
		}
	}

	_findRoute(name) {
		for (const route of this.routes) {
			if (route.name === name) {
				return route;
			}
		}

		throw new Error("Route not found: " + name);
	}

	_toQueryString(query = {}) {
		let str = "",
			separator = "";

		for (const key in query) {
			str += separator;
			str += encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
			separator = "&";
		}
		return str;
	}

	get _urlPrefix() {
		return `${window.location.protocol}//${window.location.hostname}`;
	}

	_addParams(uri, params = [], query = {}) {
		uri = this._urlPrefix + uri.replace("[", "").replace("]", "");
		for (const param of params) {
			uri = uri.replace(/{[^}]+}/, param);
		}
		if (query) {
			return `${uri}?${this._toQueryString(query)}`;
		}
		return uri;
	}

	_prepare(name, body, params, query = null, contentLanguage = null, platformLanguage = null) {
		const language = new Language();
		const {url, route} = this.composeUrl(name, params, query);

		let headers = {
			"Content-Language": contentLanguage || language.course,
			"Platform-Language": platformLanguage || language.platform
		};

		let method = route.method;
		if (body instanceof FormData) {
			if (["PATCH", "PUT"].includes(route.method)) {
				method = "post";
				body.append("_method", "PUT");
			}
		} else {
			headers["Content-Type"] = "application/json";
		}

		if (route.auth) {
			headers["Authorization"] = `Bearer ${Auth.instance.accessToken}`;
		}

		return {
			url,
			options: {
				body,
				headers,
				method,
			},
		};
	}

	async _handleResponse(response) {
		let json = response.status !== 204 ? await response.json() : {};
		json.status = response.status;
		if (response.ok) {
			return json;
		}
		return Promise.reject(json);
	}

	request(name, ...params) {
		const {url, options} = this._prepare(name, null, params.filter(p => p !== null && typeof p !== "undefined"));
		return fetch(url, options).then(this._handleResponse);
	}

	download(name, ...params) {
		const {url, options} = this._prepare(name, null, params.filter(p => p !== null && typeof p !== "undefined"));
		return fetch(url, options).then(async response => {
			// save response to file based on mime type
			const disposition = response.headers.get("Content-Disposition");
			const filename = disposition ? disposition.split("filename=")[1] : "file";
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = filename.replace(/"/g, "");
			a.click();
			URL.revokeObjectURL(url);
			a.remove();
		});
	}

	requestWithQuery(name, query, ...params) {
		const {url, options} = this._prepare(name, null, params.filter(p => p !== null && typeof p !== "undefined"));
		const queryString = new URLSearchParams(query).toString();
		return fetch(`${url}?${queryString}`, options).then(this._handleResponse);
	}

	requestLanguage(name, contentLanguage, platformLanguage, ...params) {
		const {url, options} = this._prepare(name, null, params.filter(p => p !== null && typeof p !== "undefined"), null, contentLanguage, platformLanguage);
		return fetch(url, options).then(this._handleResponse);
	}

	sendData(name, data, ...params) {
		const {
			url,
			options
		} = this._prepare(name, JSON.stringify(data), params.filter(p => p !== null && typeof p !== "undefined"));
		return fetch(url, options).then(this._handleResponse);
	}

	sendForm(name, data, ...params) {
		if (!(data instanceof FormData)) {
			data = new FormData(data);
		}
		const {url, options} = this._prepare(name, data, params.filter(p => p !== null && typeof p !== "undefined"));
		return fetch(url, options).then(this._handleResponse);
	}

	query(name, query, ...params) {
		const {
			url,
			options
		} = this._prepare(name, null, params.filter(p => p !== null && typeof p !== "undefined"), query);
		return fetch(url, options).then(this._handleResponse);
	}

	delete() {

	}

	pageAuth(url) {
		return fetch(url, {
			"headers": {
				"Content-Type": "application/json",
				"Authorization": `Bearer ${Auth.instance.accessToken}`
			}
		})
			.then(this._handleResponse);
	}

}